// app/page.tsx
"use client";
import { use, useEffect, useState } from 'react';
import Hero from "./components/hero";
import ProductCarousel from "./components/productcarosell";
import AboutUs from "./components/aboutus";
import Team from "./components/team";
import Stats from "./components/stats";
import ContactUs from "./components/contactus";
import Footer from "./components/footer";
import getStoreSettings from "./shop/settings/landingpage/action";
import { Store } from '@/utils/interfaces/interfaces';

export default function HomePage({
  params,
}: {
  params: { domain: string }
}) {
  const [settings, setSettings] = useState<Partial<Store>>({});
  const [isLoading, setIsLoading] = useState(true);
  const domain = params.domain;

  useEffect(() => {
    async function loadSettings() {
      if (!domain) return;
      const storeSettings = await getStoreSettings(domain);
      setSettings(storeSettings || {});
      setIsLoading(false);
    }
    loadSettings();
  }, [domain]);

  if (isLoading) return <div>Loading...</div>;

  const footerLinks = [
    { text: "About", href: "/about" },
    { text: "Contact", href: "/contact" }
  ];

  const socialLinks = [
    ...Object.entries(settings?.social_links || {}).map(([platform, href]) => ({
      platform,
      href: href || ""
    }))
  ];

  const teamMembers = settings?.team_members || [];
  const stats = settings?.stats || [];

  return (
    <div className="flex flex-col min-h-screen w-full">
      <main className="flex-1 w-full">
        <div className="mx-auto w-full">
          <div className="flex flex-col px-5 py-20 w-full">

            <Hero
              title={settings?.hero_title || ""}
              subtitle={settings?.hero_subtitle || ""}
              ctaText={settings?.hero_cta_text || ""}
              imageUrl={settings?.hero_image_url || "https://via.placeholder.com/150"}
              className="custom-class"
              settings={settings || {}}
            />


            <ProductCarousel title="Featured Products" subtitle="Check out our latest products" domain={domain} settings={settings || {}} />

            <AboutUs aboutTitle={settings?.about_title || ""} aboutContent={settings?.about_content || ""} aboutImageUrl={settings?.about_image_url || ""} settings={settings || {}} />


            <Team teamTitle={settings?.team_title || ""} teamDescription={settings?.team_description || ""} teamMembers={teamMembers} settings={settings || {}} />


            <Stats stats={stats} settings={settings || {}} />


            <ContactUs settings={settings || {}} email={settings?.footer_email || ""} />
          </div>
        </div>
      </main>

      <Footer
        companyName={settings?.footer_company_name || ""}
        links={footerLinks}
        socialLinks={socialLinks}
        address={settings?.footer_address || ""}
        phone={settings?.footer_phone || ""}
        email={settings?.footer_email || ""}
        settings={settings || {}}
      />
    </div>
  );
}
